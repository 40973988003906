import React from 'react';
import './BuyNowModal.css';

const BuyNowModal = ({ isOpen, onClose, onBuyNow }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>×</button>
        
        <div className="modal-mobile">
          <h1>tuttO</h1>
          <h2>The Flirting Game That Makes Everything Possible</h2>
          
          <div className="product-image-container">
            <img src="/box2.png" alt="Tutto Game Box" className="modal-product-image" />
          </div>
          
          <div className="game-contents">
            <div className="card-list">
              <div className="card-item">
                🔥 69 Spicy Question Cards
              </div>
              <div className="card-item">
                💕 220 Blush-Worthy Answer Cards
              </div>
              <div className="card-item">
                🤖 220 FlirtBot Suggestion Cards
              </div>
              <div className="card-item">
                ✨ Endless Possibilities for Flirty Fun!
              </div>
            </div>
          </div>

          <p className="description">
            Turn any gathering into an unforgettable night of laughter, 
            flirting, and connection. Perfect for date nights, parties, 
            or anywhere the mood strikes!
          </p>

          <button 
            className="modal-buy-button" 
            onClick={onBuyNow}
          >
            BUY NOW - $29
          </button>
        </div>
      </div>
    </div>
  );
};

export default BuyNowModal; 