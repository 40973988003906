import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom'; // Import useLocation
import BuyNowModal from './components/BuyNowModal';

// Manually set the number of images in each folder
const TOTAL_QUESTIONS = 14;
const TOTAL_ANSWERS = 18;

const reviews = [
  { text: '"I played the game its awesome and so relatable especially Tesla stocks and carbone spicy rigatoni 😂"' },
  { text: '"The ultimate vibe maker for dates and parties. 10/10 would embarrass myself again!"' },
  { text: '"My friends and I cant stop playing. So many unforgettable moments!"' },
];
// const phrases = [
//   'what turns you on?',
//   'tuttO è possibile',
//   'anything is possible'
// ];

function App() {
  // const [showInstructions, setShowInstructions] = useState(false);
  const [showCards, setShowCards] = useState(false);
  const [questionCard, setQuestionCard] = useState(1);
  const [answerCards, setAnswerCards] = useState([1, 2, 3, 4]);
  const [eventImages, setEventImages] = useState([]);
  const [currentReview, setCurrentReview] = useState(0);
  // const [currentPhrase, setCurrentPhrase] = useState('What turns you on?');
  const [isChanging, setIsChanging] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation(); // Get the current location at the top level

  // const toggleInstructions = () => {
  //   setShowInstructions(!showInstructions);
  // };

  // useEffect(() => {
  //   let currentIndex = 0;
  //   const intervalId = setInterval(() => {
  //     setIsChanging(true);
  //     setTimeout(() => {
  //       currentIndex = (currentIndex + 1) % phrases.length;
  //       setCurrentPhrase(phrases[currentIndex]);
  //       setIsChanging(false);
  //     }, 1000); // 1 second for fade out/in effect
  //   }, 3000); // Change phrase every 3 seconds

  //   return () => clearInterval(intervalId);
  // }, []);

  const handleBuyNowClick = () => {
    setIsModalOpen(true);
  };

  const handleModalBuyNow = () => {
    const stripeLink = location.pathname === '/game' 
      ? 'https://buy.stripe.com/28ocN1aAo3Mz32U3cg'
      : 'https://buy.stripe.com/cN26oDgYMbf17jaeUZ';
    
    window.location.href = stripeLink;
  };

  useEffect(() => {
    const cardTimer = setTimeout(() => setShowCards(true), 500);

    // Load event images
    const eventImagesContext = require.context('../public/Events', false, /\.(png|jpe?g|svg)$/);
    const images = eventImagesContext.keys().map(eventImagesContext);
    setEventImages(images);

    const reviewInterval = setInterval(() => {
      setCurrentReview(prev => {
        const nextReview = (prev + 1) % reviews.length;
        return nextReview;
      });
    }, 6000); // Increased from 3000 to 6000 ms (6 seconds per review)

    return () => {
      clearTimeout(cardTimer);
      clearInterval(reviewInterval);
    };
  }, []);

  useEffect(() => {
    const changeInterval = setInterval(() => {
      setIsChanging(true);
      setTimeout(() => {
        replaceCard('question');
        answerCards.forEach((_, index) => replaceCard('answer', index));
        setIsChanging(false);
      }, 1000); // Duration of the change animation
    }, 3000);

    return () => clearInterval(changeInterval);
  }, [answerCards]);

  const replaceCard = (type, index) => {
    if (type === 'question') {
      setQuestionCard(prevCard => (prevCard % TOTAL_QUESTIONS) + 1);
    } else {
      setAnswerCards(prevCards => {
        const newCards = [...prevCards];
        newCards[index] = (newCards[index] % TOTAL_ANSWERS) + 1;
        return newCards;
      });
    }
  };

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
    // Optional: Toggle body scroll
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest('.menu-icon') && !event.target.closest('.menu-dropdown')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <>
      <Routes>
        <Route path="/game" element={(
          <div className="App">
            <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuClick}>
              <div className="middle-line"></div>
            </div>
            <div className={`menu-overlay ${isMenuOpen ? 'open' : ''}`}>
              <div className="menu-items">
                <button 
                  className="menu-item" 
                  onClick={() => {
                    handleBuyNowClick();
                    setIsMenuOpen(false);
                  }}
                >
              Buy Now
                </button>
                <a 
                  href="https://www.instagram.com/playtutto" 
                  className="menu-item" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Instagram
                </a>
                <a 
                  href="mailto:adamjonahstudio@gmail.com" 
                  className="menu-item"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Contact
                </a>
              </div>
            </div>
            <div className="game-title">
              <img 
                src="/logoneww.png" 
                alt="play tuttO - the flirting game" 
                className="logo-image"
              />
            </div>
            <div className="game-area">
              <div 
                className={`question-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
                onClick={() => replaceCard('question')}
              >
                <img src={`/Questions/q${questionCard}.jpg`} alt="Question Card" />
              </div>
              <div className="answer-cards">
                {answerCards.map((card, index) => (
                  <div
                    key={index}
                    className={`answer-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
                    style={{ animationDelay: `${index * 0.1}s` }}
                    onClick={() => replaceCard('answer', index)}
                  >
                    {index === 3 ? (
                      <div className="turn-on-card">
                        <img src="/turnon.jpg" alt="Turn On Card" />
                        <div className="turn-on-text">Write what turns you on</div>
                      </div>
                    ) : (
                      <img src={`/Answers/a${card}.jpg`} alt={`Answer Card ${index + 1}`} />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="game-explanation">
              <h2>
                <span className="game-title-text">tuttO</span> is the spicy new party game where <br /> laughter leads to love...and unforgettable fun;) 
              </h2>
              
              <h2 className="game-rules">
             The way to play:
              <ul>
                <li>Pick a Q Card: Dive into cheeky, provocative prompts.</li>
                <li>Craft Your Turn-On: Write your wittiest, most seductive response.</li>
                <li>Flirtbot to the Rescue: Feeling shy? Get a nudge with clever suggestions.</li>
                <li>Win Your Happy Ending: The most captivating answer wins the round!</li>
              </ul>
              </h2>
              <p>Tutto isn’t just a game—it’s a moment. Unleash your charm, wit, and inner flirt in a battle for ultimate connection.</p>
            </div>
            
            <div className="laurel-container">
              <div className="laurel left"></div>
              <div className="review-container">
                <p className="review-text">{reviews[currentReview].text}</p>
              </div>
              <div className="laurel right"></div>
            </div>

            <button 
              className="buy-now-button" 
              onClick={handleBuyNowClick}
            >
              <span className="button-text">BUY NOW</span>
            </button>

            <div 
              className="product-image" 
              onClick={handleBuyNowClick}
            >
              <img src="/box.png" alt="Tutto a Game Box" />
            </div>

            <div className="gathering-text">
              Turn any gathering into an unforgettable night of laughter, flirting, and connection. Perfect for date nights, parties, or anywhere the mood strikes!
            </div>

            <div style={{ width: '100%', overflow: 'hidden' }}>
              <div className="event-gallery">
                {eventImages.map((image, index) => (
                  <img 
                    key={`event-${index}`}
                    src={image}
                    alt={`Event ${index + 1}`}
                    className="event-image"
                    loading="lazy"
                  />
                ))}
              </div>
            </div>

            <div className="app-explanation">
              <h2>
                Get flirty on the go with the tuttO app! Swipe through cheeky prompts, 
                get instant FlirtBot suggestions, and save your best lines. Perfect for 
                spontaneous moments of fun.
              </h2>
            </div>

            <div className="app-store-container" style={{
              display: "flex",
              justifyContent: "center"
            }}>
              <a 
                href="https://apps.apple.com/app/tutto-flirting-game/id6738060224" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="app-store-link"
              >
                <img 
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1705363200" 
                  alt="Download on the App Store" 
                  className="app-store-badge"
                  style={{
                    borderRadius: "13px",
                    width: "180px",
                    height: "60px"
                  }}
                />
              </a>
            </div>

            <div style={{
              fontSize: "24px",
              fontWeight: "bold",
              margin: "20px 0",
              textAlign: "center"
            }}>
              what turns you on?
            </div>

            
 

            <div className="social-links">
              <a 
                href="https://www.instagram.com/playtutto" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="instagram-link"
                style={{
                  fontSize: "32px",
                  color: "#000000",
                  marginTop: "15px",
                  display: "inline-block"
                }}
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>

            <p className="copyright">© tutto llc 2024</p>

          
          </div>
        )} />
        <Route path="/" element={(
          <div className="App">
            <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuClick}>
              <div className="middle-line"></div>
            </div>
            <div className={`menu-overlay ${isMenuOpen ? 'open' : ''}`}>
              <div className="menu-items">
                <button 
                  className="menu-item" 
                  onClick={() => {
                    handleBuyNowClick();
                    setIsMenuOpen(false);
                  }}
                >
                  Buy tuttO game
                </button>
             
                <a 
                  href="https://apps.apple.com/app/tutto-flirting-game/id6738060224" 
                  className="menu-item" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  onClick={() => setIsMenuOpen(false)}
                >
Get tuttO app                </a>
<a 
                  href="https://www.instagram.com/playtutto" 
                  className="menu-item" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  onClick={() => setIsMenuOpen(false)}
                >
               Connect on Instagram
                </a>
                <a 
                  href="mailto:adamjonahstudio@gmail.com" 
                  className="menu-item"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Contact
                </a>
              </div>
            </div>
            <div className="game-title">
              <img 
                src="/logoneww.png" 
                alt="play tuttO - the flirting game" 
                className="logo-image"
              />
            </div>
            <div className="game-area">
              <div 
                className={`question-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
                onClick={() => replaceCard('question')}
              >
                <img src={`/Questions/q${questionCard}.jpg`} alt="Question Card" />
              </div>
              <div className="answer-cards">
                {answerCards.map((card, index) => (
                  <div
                    key={index}
                    className={`answer-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
                    style={{ animationDelay: `${index * 0.1}s` }}
                    onClick={() => replaceCard('answer', index)}
                  >
                    {index === 3 ? (
                      <div className="turn-on-card">
                        <img src="/turnon.jpg" alt="Turn On Card" />
                        <div className="turn-on-text">Write what turns you on</div>
                      </div>
                    ) : (
                      <img src={`/Answers/a${card}.jpg`} alt={`Answer Card ${index + 1}`} />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="game-explanation">
              <h2>
                <span className="game-title-text">tuttO</span> is the spicy new party game where<br />laughter leads to love...<br /> & unforgettable fun :)
              </h2>
              
              <h2 className="game-rules">
<span className="game-title-text">the way to play</span> 
              <ul>
                <li>pick a <span className="game-title-text">Q</span> Card from a deck of cheeky &  <br /> deep prompts</li>
                <li>craft your <span className="game-title-text">turnOn</span>~  write your wittiest, most seductive, or laugh-out-loud response</li>
                <li>feeling shy? use the <span className="game-title-text">flirtbot</span>  for guided flirtation</li>
                <li> <span className="game-title-text">happy ending</span>~ the player with the most captivating, swoon-worthy, or sidesplitting flirts scores</li>
              </ul>
              </h2>
             </div>

             <button 
              className="buy-now-button" 
              onClick={handleBuyNowClick}
            >
              <span className="button-text">BUY NOW</span>
            </button>
            
            <div className="laurel-container">
              <div className="laurel left"></div>
              <div className="review-container">
                <p className="review-text">{reviews[currentReview].text}</p>
              </div>
              <div className="laurel right"></div>
            </div>
            <div 
              className="product-image" 
              onClick={handleBuyNowClick}
              style={{
                paddingTop: '30px',
                transform: 'scale(0.9)'  // Makes the image 90% of its original size
              }}
            >
              <img src="/box2.png" alt="Tutto Game Box" />
            </div>

            <div className="gathering-text">
              Turn any gathering into an unforgettable night of laughter, flirting, and connection. Perfect for date nights, parties, or anywhere the mood strikes!
            </div>

            <button 
              className="buy-now-button" 
              onClick={handleBuyNowClick}
            >
              <span className="button-text">BUY NOW</span>
            </button>

            <div className="brands-section">
              <p className="gathering-text">
                We partner with exceptional brands ~  spice up your next event with tuttO
              </p>
              <div className="brands-slider">
                <div className="brands-track">
                  {[1, 2].map((set) => (
                    <div key={set} className="brands-container">
                      <img src="/brands/logo1.png" alt="Brand Partner" className="brand-logo" />
                      <img src="/brands/logo2.png" alt="Brand Partner" className="brand-logo" />
                      <img src="/brands/logo3.png" alt="Brand Partner" className="brand-logo" />
                      <img src="/brands/logo4.png" alt="Brand Partner" className="brand-logo" />
                    </div>
                  ))}
                </div>
              </div>
            </div>

           

            <div style={{ 
              width: '100%', 
              overflow: 'hidden',
              paddingTop: '30px' // Add padding above the gallery
            }}>
              <div className="event-gallery">
                {eventImages.map((image, index) => (
                  <img 
                    key={`event-${index}`}
                    src={image}
                    alt={`Event ${index + 1}`}
                    className="event-image"
                    loading="lazy"
                  />
                ))}
              </div>
            </div>

            <div className="app-explanation">
              <h2>
                Get flirty on the go with the tuttO app! <br /> Swipe through cheeky prompts, 
                <br />get instant flirtbOt suggestions,<br /> and save your turnOns.<br /> Perfect for 
                spontaneous moments of fun.
              </h2>
            </div>

            <div className="app-store-container" style={{
              display: "flex",
              justifyContent: "center"
            }}>
              <a 
                href="https://apps.apple.com/app/tutto-flirting-game/id6738060224" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="app-store-link"
              >
                <img 
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1705363200" 
                  alt="Download on the App Store" 
                  className="app-store-badge"
                  style={{
                    borderRadius: "13px",
                    width: "180px",
                    height: "60px"
                  }}
                />
              </a>
            </div>

            <div style={{
              fontSize: "24px",
              fontWeight: "bold",
              margin: "20px 0",
              textAlign: "center"
            }}>
              what turns you on?
            </div>

           
            {/* {showInstructions && (
              <div className="instructions-images">
                <img src="/front.jpg" alt="Game instructions front" className="instruction-image" />
                <img src="/back.jpg" alt="Game instructions back" className="instruction-image" />
              </div>
            )} */}

            <div className="social-links">
              <a 
                href="https://www.instagram.com/playtutto" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="instagram-link"
                style={{
                  fontSize: "32px",
                  color: "#000000",
                  marginTop: "15px",
                  display: "inline-block"
                }}
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>

            <p className="copyright">© tutto llc 2024</p>

            
          </div>
        )} />
      </Routes>
      
      <BuyNowModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onBuyNow={handleModalBuyNow}
      />
    </>
  );
}

export default App;